<template>
    <div class="gb-actions-library-query">
        <search-query v-bind="$attrs" v-on="$listeners">
            <template v-slot:default="{query, handleSearch}">
                <el-input
                    v-model.trim="query.actionName"
                    class="w240 mr40"
                    placeholder="动作"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"
                ></el-input>
                <gb-dictionary
                    ref="xssj"
                    v-model="query.actionSubject"
                    class="w240 mr40"
                    group="销售运营动作主题"
                    :clearable="true"
                    :filterable="true"
                    placeholder="主题选择"
                    @change="handleSearch"
                >
                </gb-dictionary>
                <div class="monitoring-time mr40">
                    <span>监控时长</span>
                    <el-input
                        v-model.trim="query.minMonitorInterval"
                        class="w60"
                        maxlength="3"
                        type="number"
                        @clear="handleSearch"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                    <span>~</span>
                    <el-input
                        v-model.trim="query.maxMonitorInterval"
                        class="w60"
                        maxlength="3"
                        type="number"
                        @clear="handleSearch"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                </div>
                <el-select
                    v-model="query.actionIndicatorId"
                    class="w240 mr40"
                    placeholder="指标选择"
                    clearable
                    :filterable="true"
                    @clear="handleSearch"
                    @change="handleSearch"
                >
                    <el-option
                        v-for="result in transfer.allIndicator"
                        :key="result.indicatorId"
                        :label="result.title"
                        :value="result.indicatorId"
                    >
                    </el-option>
                </el-select>
            </template>
        </search-query>
    </div>
</template>
<script>
import _ from 'lodash'
import gbDictionary from '@/components/dictionary'

const SearchQuery = () => import('components/search-query')

export default {
    name: 'ActionsLibraryQuery',
    components: {
        SearchQuery,
        gbDictionary
    },
    inject: ['transfer'],
    mounted () {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.gb-actions-library-query {
    .w240 {
        width: 240px;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    .w60 {
        width: 60px;
    }

    .monitoring-time {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;

        span {
            margin: 0 10px;
            color: #c0c4cc;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}
</style>
